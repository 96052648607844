<template>
  <el-card shadow="never">
    <div class="card-header">
      <el-page-header @back="goBack">
        <template #content>
          <span class="text-large font-600 mr-3"> 修改密码 </span>
        </template>
      </el-page-header>
    </div>
  </el-card>
  <el-card shadow="never" style="margin-top:20px">
    <el-form :model="formData" label-width="120px" style="width: 500px;">
      <el-form-item label="旧密码">
        <el-input type="password" show-password v-model="formData.oldpwd" />
      </el-form-item>
      <el-form-item label="新密码">
        <el-input type="password" show-password v-model="formData.pwd" />
      </el-form-item>
      <el-form-item label="重复密码">
        <el-input type="password" show-password v-model="formData.repwd" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="save">保存</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
  import { ElMessage } from 'element-plus'
  import api from '@/api/index.js'
  import { defineComponent, ref } from 'vue'
  export default defineComponent({
    setup() {
      const formData = ref({
        oldpwd:'',
        pwd:'',
        repwd:'',
      })
      return {
        formData,
      }
    },
    methods: {
      save(){
        let that = this
        if(that.formData.pwd != that.formData.repwd){
          ElMessage.error('密码和确认密码不一致')
          return false
        }
        api.shopApi.changePwd(that.formData).then(res => {
            if (res.code === 0) {
              ElMessage({
                message: res.message,
                type: 'success',
              })
              that.formData.oldpwd = ''
              that.formData.pwd = ''
              that.formData.repwd = ''
            }else{
              ElMessage.error(res.message)
            }
        }).catch((res) => {
            ElMessage.error(res.message);
        })
      },
      goBack(){
        this.$router.go(-1)
      },
    }
  })
</script>